// store/modules/common.ts
/**
 * 공통으로 사용하는 store
 * v1.0
 * 
 * 로그인
 * 로그아웃
 * 팝업
 * locale
 * 로딩 show
 * 로딩 hide
 * 토큰 갱신
 * 메인으로
 * 파일 업로드
 * 
 */
import { Module } from "vuex";
import { loadingController } from "@ionic/vue";
import CryptoJS from "crypto-js";
import { callAPI, fileDirectUploadAPI, fileUploadAPI } from "@/common/commonAPI";
import { RootState } from "../index";

export interface CommonState {
  LOCALE_NAME: string,
  EMAIL_NAME: string,
  USER_INFO_NAME: string,
  // TOKEN_NAME: string,
  AUTH_NAME: string,
  PRIVATE_KEY: string,
  popup_show: boolean,
  locale: string,
  email: string,
  user_info: object,
  auth: string,
  auth_init: object,
  loading_show: boolean,
}

export const commonModule: Module<CommonState, RootState> = {
  state: () => ({
    LOCALE_NAME: "IW_LOCALE",
    EMAIL_NAME: "IW_T_EMAIL",
    USER_INFO_NAME: "IW_IF",
    // TOKEN_NAME: "IW_K",
    AUTH_NAME: "IW",
    PRIVATE_KEY: "IW_PRIVATE_KEY_dlwldnpq",
    popup_show: false,
    locale: "",
    email: "",
    user_info: {},
    auth: "",
    auth_init: {
      "accessToken": "",
      "refreshToken": ""
    },
    loading_show: false,
  }),
  getters: {
    getPopupShow(state) {
      return state.popup_show;
    },
    getLocale(state) {
      if (state.locale == "") {
        return sessionStorage.getItem(state.LOCALE_NAME);
      }
      return state.locale;
    },
    getEmail(state) {
      if (state.email == "") {
        return sessionStorage.getItem(state.EMAIL_NAME);
      }
      return state.email;
    },
    getUserInfo(state) {
      if (JSON.stringify(state.user_info) == "{}") {
        try {
          return JSON.parse(localStorage.getItem(state.USER_INFO_NAME)!);
        } catch (e) {
          return state.user_info;
        }
      }
      return state.user_info;
    },
    getAuth(state) {
      if (state.auth == "") {
        //가져오기
        const temp = localStorage.getItem(state.AUTH_NAME);
        if (temp != null) {
          //복호화
          try {
            const bytes = CryptoJS.AES.decrypt(temp, state.PRIVATE_KEY);
            return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          } catch (e: any) {
            return state.auth_init;
          }
        } else {
          return state.auth_init;
        }

      }
      return state.auth;
    },
  },
  mutations: {
    setPopupShow(state, value: boolean) {
      if (value) {
        //scroll 금지
        const target = window.document.getElementsByTagName("html")[0];
        target.style.overflowX = 'hidden';
        target.style.overflowY = 'hidden';
      } else {
        //scroll 열기
        const target = window.document.getElementsByTagName("html")[0];
        target.style.overflowX = 'auto';
        target.style.overflowY = 'auto';
      }
      state.popup_show = value;
    },
    setLocale(state, value) {
      if (value == "") {
        state.locale = value;
        sessionStorage.removeItem(state.LOCALE_NAME);
      } else {
        state.locale = value;
        sessionStorage.setItem(state.LOCALE_NAME, value);
      }
    },
    setEmail(state, value) {
      if (value == "") {
        state.email = value;
        sessionStorage.removeItem(state.EMAIL_NAME);
      } else {
        state.email = value;
        sessionStorage.setItem(state.EMAIL_NAME, value);
      }
    },
    setUserInfo(state, value) {
      if (JSON.stringify(value) == "{}") {
        localStorage.removeItem(state.USER_INFO_NAME);
      } else {
        state.user_info = value;
        localStorage.setItem(state.USER_INFO_NAME, JSON.stringify(state.user_info));
      }
    },
    setAuth(state, value) {
      if (value == "") {
        //삭제
        state.auth = value;
        localStorage.removeItem(state.AUTH_NAME);
        // localStorage.removeItem(state.TOKEN_NAME);
      } else {
        state.auth = value;
        //암호화
        const temp = CryptoJS.AES.encrypt(JSON.stringify(value), state.PRIVATE_KEY).toString();
        //저장
        localStorage.setItem(state.AUTH_NAME, temp);
        // //refresh token사용을 위해서 저장
        // //보안상 issue가 있을수 있으므로, 해결책 고민필요
        // localStorage.setItem(state.TOKEN_NAME, value.refreshToken);
      }
    },
  },
  actions: {
    showLoading({ state }, data = { type: "icon", msg: "", duration: 10000 }) {
      if (!state.loading_show) {
        state.loading_show = true;

        loadingController.create({
          cssClass: data.type,
          message: data.msg,
          duration: data.duration,
          spinner: "circles"
        }).then((response) => {
          //500ms후에도 hide가 호출되지 않았으면 show
          setTimeout(() => {
            if( state.loading_show ){
              response.present();

              setTimeout(() => {
                response.dismiss().catch(console.error);
                state.loading_show = false;
              }, data.duration);
            }
          }, 500)

        })
      }
    },
    hideLoading({ state }) {
      setTimeout(() => {
        state.loading_show = false;
        loadingController.dismiss().catch(console.error);
      }, 300);
    },
    //token 갱신
    async refreshToken({ getters, commit, dispatch }) {

      // dispatch("showLoading");

      const url = '/auth/token/access'
      const param = {};
      const token = getters.getAuth;
      try {
        const result = await callAPI(url, param, "post", token.refreshToken);

        //access token 갱신
        token.accessToken = result.accessToken;
        commit("setAuth", token);

        return true;

      } catch (e: any) {
        // console.log(e);

        //갱신 실패하면 token 삭제
        commit("setAuth", "");
        return false;
      }
      // finally{
      //   store.dispatch("hideLoading");
      // }
    },
    async logout({ commit, dispatch }) {
      commit("setAuth", "");
      commit("setUserInfo", {});

      dispatch("goMain");

      // //TODO:invalid token 에러 뜸
      // const url = `/auth/logout`;
      // const param = {}
      // try {
      //   const result = await callAPI(url, param);

      //   console.log(result);
      // } catch (e) {
      //   console.log(e);
      // }
    },
    //메인으로
    goMain({commit}) {
      // let org = "???";
      // if( sessionStorage.getItem("haute_rium_from") != null && sessionStorage.getItem("haute_rium_from") != undefined ){
      //   org = org + sessionStorage.getItem("haute_rium_from");
      // }
      const org = "/";
      window.location.href = org;
    },
    // //파일 업로드
    // async fileUpload({ getters }, img_object: any) {
    //   const token = getters.getAuth;

    //   const formData = new FormData(); // formData 객체를 생성한다.
    //   formData.append("file", img_object.file);

    //   //slash 인지 s3에 올려야 하는지 확인
    //   const temp = img_object.path.split("/");
    //   if (temp[temp.length - 1] == "") {
    //     //slash에 업로드
    //     //call api - upload
    //     const url = `/upload/${img_object.token}`;
    //     const result = await fileUploadAPI(url, formData, token.accessToken);

    //     //200 - 이미지 upload 성공
    //     return true;
    //   } else {
    //     //s3에 업로드
    //     //call api - upload
    //     const url = `${img_object.token}`;
    //     const result = await fileDirectUploadAPI(url, img_object.file, token.accessToken, img_object.path);

    //     //200 - 이미지 upload 성공
    //     return true;
    //   }
    // },
  },
}