
const signStore = {
    state: () => ({
        USER_DETAIL_01_01_NAME: "HAUTE_RIUM_USER_DETAIL_01_01",
        USER_DETAIL_01_02_NAME: "HAUTE_RIUM_USER_DETAIL_01_02",
        USER_DETAIL_01_03_NAME: "HAUTE_RIUM_USER_DETAIL_01_03",
        USER_DETAIL_01_04_NAME: "HAUTE_RIUM_USER_DETAIL_01_04",
        USER_DETAIL_02_NAME: "HAUTE_RIUM_USER_DETAIL_02",
        admin_popup_show: false,
        /**
         * 상태값
         * "" ->  api 호출 전
         * {} ->  api 호출 후 값이 없음
         * {~} -> api 호출 후 값이 있음
         */
        user_detail_01_01: "",
        user_detail_01_02: "",
        user_detail_01_03: "",
        user_detail_01_04: "",
        user_detail_02: "",
        user_detail_02_init: {
          "gender": "",
          "birthYear": 2000,
          "companyName": "",
          "department": "",
          "position": "",
          "about": ""
        },
        user_detail_02_file: null,
        user_detail_02_file_src: null,
        mypage: "",
        mypage_init: {
          phone: {
            countryCode: "",
            areaCode: "",
            prefix: "",
            lineNumber: ""
          },
          userDetail: {}
        },
        booth_view: "",
      }),
      getters: {
          getUserDetail0101(state : any){
            if( state.user_detail_01_01 == "" ){
              if( sessionStorage.getItem(state.USER_DETAIL_01_01_NAME) == null ){
                return "";
              }else {
                return sessionStorage.getItem(state.USER_DETAIL_01_01_NAME);
              }
            }
            return state.user_detail_01_01;
          },
          getUserDetail0102(state: any){
            if( state.user_detail_01_02 == "" ){
              if( sessionStorage.getItem(state.USER_DETAIL_01_02_NAME) == null ){
                return "";
              }else {
                return sessionStorage.getItem(state.USER_DETAIL_01_02_NAME);
              }
            }
            return state.user_detail_01_02;
          },
          getUserDetail0103(state: any){
            if( state.user_detail_01_03 == "" ){
              if( sessionStorage.getItem(state.USER_DETAIL_01_03_NAME) == null ){
                return "";
              }else {
                return sessionStorage.getItem(state.USER_DETAIL_01_03_NAME);
              }
            }
            return state.user_detail_01_03;
          },
          getUserDetail0104(state: any){
            if( state.user_detail_01_04 == "" ){
              if( sessionStorage.getItem(state.USER_DETAIL_01_04_NAME) == null ){
                return "";
              }else {
                return sessionStorage.getItem(state.USER_DETAIL_01_04_NAME);
              }
            }
            return state.user_detail_01_04;
          },
          getUserDetail02(state: any){
            if( state.user_detail_02 == "" ){
              if( sessionStorage.getItem(state.USER_DETAIL_02_NAME) == null ){
                return state.user_detail_02_init;
              }else {
                return JSON.parse(sessionStorage.getItem(state.USER_DETAIL_02_NAME)!);
              }
            }
            return state.user_detail_02;
          },
          getUserDetail02File(state: any){
            return state.user_detail_02_file;
          },
          getUserDetail02FileSrc(state: any){
            return state.user_detail_02_file_src;
          },
      },
      mutations: {
        setUserDetail0101(state: any, value: any) {
            if( value == "" ){
              state.user_detail_01_01 = value;
              sessionStorage.removeItem(state.USER_DETAIL_01_01_NAME);
            }else{
              state.user_detail_01_01 = value;
              sessionStorage.setItem(state.USER_DETAIL_01_01_NAME, value);
            }
          },
          setUserDetail0102(state: any, value : any) {
            if( value == "" ){
              state.user_detail_01_02 = value;
              sessionStorage.removeItem(state.USER_DETAIL_01_02_NAME);
            }else{
              state.user_detail_01_02 = value;
              sessionStorage.setItem(state.USER_DETAIL_01_02_NAME, value);
            }
          },
          setUserDetail02(state: any, value : any) {
            if( value == "" ){
              state.user_detail_02 = value;
              sessionStorage.removeItem(state.USER_DETAIL_02_NAME);
            }else{
              state.user_detail_02 = value;
              sessionStorage.setItem(state.USER_DETAIL_02_NAME, JSON.stringify(value));
            }
          },
          setUserDetail02File(state: any, value : any) {
            state.user_detail_02_file = value;
          },
          setUserDetail02FileSrc(state: any, value : any) {
            state.user_detail_02_file_src = value;
          },
      },
      actions:{
        toastPopupActive(_state : any,comment : string = '완료'){
          const toastEl = document.createElement('div');
          toastEl.innerHTML = comment;
          document.body.appendChild(toastEl);
          toastEl.classList.add('popup--toast');
          setTimeout(() => { toastEl.classList.add('active') })
          setTimeout(() => { toastEl.classList.add('removed') }, 2000);
          setTimeout(()=>{toastEl.remove() },2600)
        },
        bodyScrollSwitch(_state: any, a : boolean = true){
          if( a ){
            document.getElementsByTagName('body')[0]?.classList.remove("scrollDisable");
          }else {
            document.getElementsByTagName('body')[0]?.classList.add("scrollDisable");
          }
        }
      }
}

export default signStore;
