import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import store from "./store";

/* DayJs */
import * as dayjs from 'dayjs'
import 'dayjs/locale/ko'

/* vue-i18n */
import { createI18n } from "vue-i18n"; // vue-i18n import
import messages from "./i18n"; // message import

/* ionic */
import { IonicVue } from '@ionic/vue'

/* swiper */
import SwiperClass, { Pagination, Navigation } from 'swiper'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

/* common css */
import './assets/css/basic_public.css'
import './assets/css/layout.css'
import './assets/css/adm.css'
import './assets/css/mypage.css'
import './assets/css/boothlist.css'
import './assets/css/booth2d.css'
import './assets/css/booth3d.css'
import './assets/css/main.css'
import './assets/css/about.css'
import './assets/css/newsevent.css'
import './assets/css/intergrity.css'


/* animation css */
import './assets/css/animations.css'

// use swiper modules
SwiperClass.use([Pagination, Navigation]);

const app = createApp(App).use(router).use(store).use(IonicVue).use(VueAwesomeSwiper);

/* DayJs */
dayjs.locale('ko')

if (process.env.NODE_ENV == 'development')
  app.provide('$dayjs', dayjs) // provide $dayjs
else app.provide('$dayjs', dayjs.default) // provide $dayjs

app.config.globalProperties.$dayjs = dayjs
/* \DayJs */

// create vue-i18n object
const i18n = createI18n({
  // something vue-i18n options here ...
  warnHtmlMessage: false, // disable warning HTML in message
  legacy: false,
  locale: "Korea",
  fallbackLocale: "English",
  messages,
});

app.use(i18n);

app.mount('#app')

// createApp(App).mount('#app')
