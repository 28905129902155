import { useRoute } from "vue-router";

//for client dataList
const clientListStore = {
    state: () => ({
        CLIENT_PAGING_INFO:"IW_API",      //페이지 새로 고침에도 정보를 유지하기위한 value
        /** 
         * 페이지 정보를 저장
         * <ex> { curr: 0, sort: 'id', howMany:10, searchValue:{} }
         * watch에서 잘 동작하기 위하여 json을 string 형식으로 저장해서 사용한다.
         */
        clientPageInfo: null,

        clientCallApiTime:"",      //리스트 호출 시간
        clientPagingTotal:0,       //전체 아이템 갯수,
      }),
      getters: {
        getClientCallApiTime(state : any){return state.clientCallApiTime;},
        getClientPagingTotal(state : any){return state.clientPagingTotal;},
        getClientPageInfo(state : any){
          if( state.clientPageInfo == null ){
            state.clientPageInfo = sessionStorage.getItem(state.CLIENT_PAGING_INFO)??"{}";
          }
          return state.clientPageInfo;
        },
      },
      mutations: {
        setClientCallApiTime(state : any, value : any){state.clientCallApiTime = value;},
        setClientPagingTotal(state : any, value : any){state.clientPagingTotal = value;},
        setClientPageInfo(state : any, value : any){
          if( value == null ){
            sessionStorage.removeItem(state.CLIENT_PAGING_INFO);
          }else {
            sessionStorage.setItem(state.CLIENT_PAGING_INFO, value);
          }
          state.clientPageInfo = value;
        },
      },
      actions:{
        toastPopupActive(_state : any,comment : string = '완료'){
          const toastEl = document.createElement('div');
          toastEl.innerHTML = comment;
          document.body.appendChild(toastEl);
          toastEl.classList.add('popup--toast');
          setTimeout(() => { toastEl.classList.add('active') })
          setTimeout(() => { toastEl.classList.add('removed') }, 2000);
          setTimeout(()=>{toastEl.remove() },2600)
        },
        bodyScrollSwitch(_state: any, a : boolean = true){
          if( a ){
            document.getElementsByTagName('body')[0]?.classList.remove("scrollDisable");
          }else {
            document.getElementsByTagName('body')[0]?.classList.add("scrollDisable");
          }
        }
      }
}

export default clientListStore;
