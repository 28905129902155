import { useRoute } from "vue-router";

//for adm dataList
const admListStore = {
  state: () => ({
    ADMIN_PAGING_INFO: "IW_API",      //페이지 새로 고침에도 정보를 유지하기위한 value
    /** 
     * 페이지 정보를 저장
     * <ex> { curr: 0, sort: 'id', howMany:10, searchValue:{} }
     * watch에서 잘 동작하기 위하여 json을 string 형식으로 저장해서 사용한다.
     */
    admPageInfo: null,
    admCallApiTime: "",      //리스트 호출 시간
    admPagingTotal: 0,       //전체 아이템 갯수,

    /**
     * popup에서 사용하는 변수
     */
    admPopupPageInfo: null,
    admPopupCallApiTime: "",      //리스트 호출 시간
    admPopupPagingTotal: 0,       //전체 아이템 갯수,

    /**
    * statPopup에서 사용하는 변수
    */
    admStatPopupPageInfo: null,
    admStatPopupCallApiTime: "",      //리스트 호출 시간
    admStatPopupPagingTotal: 0,       //전체 아이템 갯수,
  }),
  getters: {
    getAdmCallApiTime(state: any) { return state.admCallApiTime; },
    getAdmPagingTotal(state: any) { return state.admPagingTotal; },
    getAdmPageInfo(state: any) {
      if (state.admPageInfo == null) {
        state.admPageInfo = sessionStorage.getItem(state.ADMIN_PAGING_INFO) ?? "{}";
      }
      return state.admPageInfo;
    },
    getAdmPopupCallApiTime(state: any) { return state.admPopupCallApiTime; },
    getAdmPopupPagingTotal(state: any) { return state.admPopupPagingTotal; },
    getAdmPopupPageInfo(state: any) { return state.admPopupPageInfo ?? "{}"; },
    
    getAdmStatPopupCallApiTime(state: any) { return state.admStatPopupCallApiTime; },
    getAdmStatPopupPagingTotal(state: any) { return state.admStatPopupPagingTotal; },
    getAdmStatPopupPageInfo(state: any) { return state.admStatPopupPageInfo ?? "{}"; },
  },
  mutations: {
    setAdmCallApiTime(state: any, value: any) { state.admCallApiTime = value; },
    setAdmPagingTotal(state: any, value: any) { state.admPagingTotal = value; },
    setAdmPageInfo(state: any, value: any) {
      if (value == null) {
        sessionStorage.removeItem(state.ADMIN_PAGING_INFO);
      } else {
        sessionStorage.setItem(state.ADMIN_PAGING_INFO, value);
      }
      state.admPageInfo = value;
    },
    setAdmPopupCallApiTime(state: any, value: any) { state.admPopupCallApiTime = value; },
    setAdmPopupPagingTotal(state: any, value: any) { state.admPopupPagingTotal = value; },
    setAdmPopupPageInfo(state: any, value: any) { state.admPopupPageInfo = value; },

    setAdmStatPopupCallApiTime(state: any, value: any) { state.admStatPopupCallApiTime = value; },
    setAdmStatPopupPagingTotal(state: any, value: any) { state.admStatPopupPagingTotal = value; },
    setAdmStatPopupPageInfo(state: any, value: any) { state.admStatPopupPageInfo = value; },
  },
}

export default admListStore;
