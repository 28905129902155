// store/index.js
import { createStore } from "vuex";
import { callAPI, sleep } from "@/common/commonAPI";
import { commonModule, CommonState } from "./modules/commonModule";
import admListStore from "./modules/admList";
import clientListStore from "./modules/clientList";
import signStore from "./modules/sign";
export interface RootState {
  orgData: Object | null;
}

export default createStore({
  modules: {
    commonModule: commonModule,
    admListStore: admListStore,
    clientListStore: clientListStore,
    signStore: signStore,
  },
  state: () => ({
    USER_DETAIL_01_01_NAME: "HAUTE_RIUM_USER_DETAIL_01_01",
    USER_DETAIL_01_02_NAME: "HAUTE_RIUM_USER_DETAIL_01_02",
    USER_DETAIL_01_03_NAME: "HAUTE_RIUM_USER_DETAIL_01_03",
    USER_DETAIL_01_04_NAME: "HAUTE_RIUM_USER_DETAIL_01_04",
    USER_DETAIL_02_NAME: "HAUTE_RIUM_USER_DETAIL_02",
    ORGDATA_NAME: "IW_ORN",
    ALERT_POPUP_NAME: "IW_AP",
    ALERT_POPUP_NAME2: "IW_AP2",
    orgData: null, //수정 전 org data
    alertPopup: null as null|String, //popup
    alertPopup2: null as null|String, //popup
    /**
     * 상태값
     * "" ->  api 호출 전
     * {} ->  api 호출 후 값이 없음
     * {~} -> api 호출 후 값이 있음
     */
  }),
  getters: {
    getOrgData(state) {
      if (state.orgData == null) {
        state.orgData = JSON.parse(sessionStorage.getItem(state.ORGDATA_NAME) ?? "{}");
      }
      return state.orgData;
    },
    getFileStartPath() {
      // if (process.env.NODE_ENV == "development") {
      //   return process.env.VUE_APP_ROOT_API_URL + "/public/files/";
      // }else {
      //   return "/public/files/";
      // }
      // return "http://3.35.143.229/public/files/";
      return "https://ictwow.com/public/files/";
    },
    getFileStartPath2() {
      // return "http://3.35.143.229/public/board/";
      return "https://ictwow.com/public/board/";
    },
    getIntegrityFileStartPath() {
      return "https://ictwow.com/public/integrity/";
    },
    getAlertPopup(state) {
      if (state.alertPopup == null) {
        state.alertPopup = sessionStorage.getItem(state.ALERT_POPUP_NAME)?? "true";
      }
      return state.alertPopup;
    },
    getAlertPopup2(state) {
      if (state.alertPopup2 == null) {
        state.alertPopup2 = sessionStorage.getItem(state.ALERT_POPUP_NAME2)?? "true";
      }
      return state.alertPopup2;
    },
  },
  mutations: {
    setOrgData(state, value) {
      if (value == null) {
        sessionStorage.removeItem(state.ORGDATA_NAME);
      } else {
        sessionStorage.setItem(state.ORGDATA_NAME, JSON.stringify(value));
      }
      state.orgData = value;
    },
    setAlertPopup(state, value) {
      if (value == null) {
        sessionStorage.removeItem(state.ALERT_POPUP_NAME);
      } else {
        sessionStorage.setItem(state.ALERT_POPUP_NAME, value);
      }
      state.alertPopup = value;
    },
    setAlertPopup2(state, value) {
      if (value == null) {
        sessionStorage.removeItem(state.ALERT_POPUP_NAME2);
      } else {
        sessionStorage.setItem(state.ALERT_POPUP_NAME2, value);
      }
      state.alertPopup2 = value;
    },
  },
  actions: {
    //get booth view
    async getBoothView({ getters, commit, dispatch }, idx: string) {

      dispatch("showLoading");

      const url = `/booth_contents/${idx}`;
      const param = {};
      const token = getters.getAuth;
      try {
        const result = await callAPI(url, param, "get", token.accessToken);

        commit("setBoothView", result.boothContents);

        return true;

      } catch (e: any) {
        return false;
      }
      finally {
        dispatch("hideLoading");
      }
    },
    //호스트 센터 메인으로
    goHCMain({commit}) {
      const org = "/hc";
      window.location.href = org;
    },
  },
});
